import "core-js/stable";
import "regenerator-runtime/runtime";
import 'element-closest-polyfill';
import 'whatwg-fetch';
import 'element-remove';
import 'datalist-polyfill';

import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["addFieldsButton", "jsDrivenSelect", "newDsFields", "datalistInput", "hiddenInput", "newToolInput", "newToolComponents", "specificLength", "customFieldContainer", "needsCustomFields", "fieldsContainer", "trContent", "trContentInput", "newFields", "fieldsRow", "destroyField", "jsDrivenSelectUnitRelationship", "form" ]

  initialize() {
    if (!this.data.has("initialized")) {
      let formName = this.data.get('formtype');
      if (formName == 'part') {
        this.loadData("/part_resources", this.loadSelects);
      } else if (formName == 'tech_spec') {
        this.loadData("/part_resources", this.loadSelects);
      } else if (formName == 'tech_resource') {
        this.loadData("/part_resources", this.loadSelects);
      } else {
        this.initializeDatalists(this.datalistInputTargets);
      }
    }
    this.data.set('initialized', true);
    this.data.set('counter', 0)
    
  }

  connect() {
    if (this.hasNeedsCustomFieldsTarget) {
      this.addCustomSelectOption();
    }
    console.log("connecting form controller");

    let loops = 30;
    for (let i = 0; i < loops; i++){
      $('#tech_spec_unit_relationships__unit_id' + i).on('select2:select', function() {
        let event = new Event('change', {bubbles: true})
        this.dispatchEvent(event);
      });
      $('#part_unit_relationships__unit_id' + i).on('select2:select', function() {
        let event = new Event('change', {bubbles: true})
        this.dispatchEvent(event);
      });
      $('#part_symptoms__name' + i).on('select2:select', function() {
        let event = new Event('change', {bubbles: true})
        this.dispatchEvent(event);
      });
    }
  }

  addFormFields(e) {
    let fieldName = e.target.id.replace('add_', '');

    let newRow = this.newFieldsTargets.filter(r => r.dataset.field == fieldName)[0];
    let container = this.fieldsContainerTargets.filter(c => c.dataset.field == fieldName)[0];
    let foo = newRow.cloneNode(true);
    foo.removeAttribute("id");
    foo.setAttribute('data-target', "form.fieldsRow");
    for (let input of foo.querySelectorAll("select, input, textarea")) {  
      input.removeAttribute('disabled');
      input.classList.remove("disabled");
    }

    // this.initializeDatalists(this.datalistInputTargets);
    container.appendChild(foo);
    let formName = this.data.get('formtype');
    if (formName == "tech_spec") { 
        if ((fieldName == "symptom") || (fieldName == "unit_relationship") || (fieldName == "dimension") || (fieldName == "tech_resource")) {
          let lastChildRow = container.lastChild;
          let select2TargetElement = lastChildRow.querySelector("select")
          let actualCounter = this.data.get('counter');
          actualCounter = actualCounter + 1;
          let actualCounterId = "select2Row" + formName + fieldName + actualCounter;
          select2TargetElement.setAttribute('id', actualCounterId);
          $('#' + actualCounterId).select2({
            placeholder: "Select option"
          });
          $('#' + actualCounterId).on('select2:select', function() {
            let event = new Event('change', {bubbles: true})
            this.dispatchEvent(event);
          })
          this.data.set('counter', actualCounter)
          console.log(container)
          if (fieldName == "unit_relationship") {
            tippy('.tippy-unit-specific-data', {
                content: "Use when a product fits more than one unit and we need to qualify application or explain critical features that don’t apply to all units (if info applies to all units, it belongs in the 'application info' field). Write in brief sentence format unless sentence is unnecessary, then write in title case (see tool tip on 'Application info' field for examples). <ul style='margin-left: -15px'><li>       Data in this field will appear ONLY on associated unit page(s). You must therefore ensure it’s also covered in the part description, otherwise no one on part page will see it. </li><li>        <span style='font-weight: bold'>See Intranet Style Guide “Terminology”</span> for year/date/early vs. late style formatting.</li></ul>",
                allowHTML: true,
                placement: 'right',
                theme: 'sonnax',
            });
            tippy('.tippy-unit-ref-code', {
              content: "If unit has an exploded view diagram, enter the product ID number in this field. Performance converter kits must be assigned '0'. Only enter one number and do not use letters or give numbers that don’t exist in exploded view. Consult with PLM if you are unsure what number to use; sometimes the same number will be used for multiple products.",
              allowHTML: true,
              placement: 'right',
              theme: 'sonnax',
            }); 
          }
        }
    }

    if (formName == "part") { 
      if ((fieldName == "symptom") || (fieldName == "unit_relationship") || (fieldName == "dimension")) {
        let lastChildRow = container.lastChild;
        let select2TargetElement = lastChildRow.querySelector("select")
        let actualCounter = this.data.get('counter');
        actualCounter = actualCounter + 1;
        let actualCounterId = "select2Row" + formName + fieldName + actualCounter;
        select2TargetElement.setAttribute('id', actualCounterId);
        $('#' + actualCounterId).select2({
          placeholder: "Select option"
        });
        $('#' + actualCounterId).on('select2:select', function() {
          let event = new Event('change', {bubbles: true})
          this.dispatchEvent(event);
        })
        this.data.set('counter', actualCounter)
        console.log(container)
        if (fieldName == "unit_relationship") {
          tippy('.tippy-unit-specific-data', {
              content: "Use when a product fits more than one unit and we need to qualify application or explain critical features that don’t apply to all units (if info applies to all units, it belongs in the 'application info' field). Write in brief sentence format unless sentence is unnecessary, then write in title case (see tool tip on 'Application info' field for examples). <ul style='margin-left: -15px'><li>       Data in this field will appear ONLY on associated unit page(s). You must therefore ensure it’s also covered in the part description, otherwise no one on part page will see it. </li><li>        <span style='font-weight: bold'>See Intranet Style Guide “Terminology”</span> for year/date/early vs. late style formatting.</li></ul>",
              allowHTML: true,
              placement: 'right',
              theme: 'sonnax',
          }); 
          tippy('.tippy-unit-ref-code', {
            content: "If unit has an exploded view diagram, enter the product ID number in this field. Performance converter kits must be assigned '0'. Only enter one number and do not use letters or give numbers that don’t exist in exploded view. Consult with PLM if you are unsure what number to use; sometimes the same number will be used for multiple products.",
            allowHTML: true,
            placement: 'right',
            theme: 'sonnax',
          }); 
          tippy('.tippy-feature', {
            content: "This field is ONLY for use by Marketing. Turning on 'Featured' status will cause an enabled product to be displayed as 'Featured' on the associated unit page.",
            allowHTML: true,
            placement: 'right',
            theme: 'sonnax',
          }); 
        }


      }
    }



    if (newRow.hasAttribute("data-cl")) {
      const ocl = parseInt(newRow.getAttribute("data-cl"));
      const ncl = ocl + 1;
      const ors = newRow.getAttribute("data-rs");
      const orsn = newRow.getAttribute("data-rsn");
      const nrs = newRow.getAttribute("data-rs").replace(ocl, ncl);
      const nrsn = newRow.getAttribute("data-rsn").replace(ocl, ncl);
      for (let child of newRow.querySelectorAll("select, input, textarea, datalist")) {  
        if (child.hasAttribute("id")) {
          child.id = child.id.replace(ors, nrs);
        }
        if (child.hasAttribute("list")) {
          child.setAttribute("list", child.getAttribute("list").replace(ors, nrs))
        }
        if (child.hasAttribute("data-datalistfor")) {
          child.setAttribute("data-datalistfor", child.getAttribute("data-datalistfor").replace(ors, nrs))
        }
        if (child.hasAttribute("name")) {
          child.name = child.name.replace(orsn, nrsn);
        }
      }
      newRow.setAttribute("data-cl", ncl);
      newRow.setAttribute("data-rs", nrs);
      newRow.setAttribute("data-rsn", nrsn);
    }
  }

  initializeDatalists(lists) {
    lists.forEach(dl => {
      const list = dl.parentNode.querySelector("#"+dl.getAttribute("list"));
      let ft = this.data.get('formtype') == "tsp_workflow" ? "tech_spec" : this.data.get('formtype');
      let select = dl.parentNode.querySelector("#"+ft+"_"+dl.dataset.datalistfor);
      if (dl.dataset.datalisttype == "multiple" && select.value != '') {
        const label = document.querySelector("label[for="+ft+"_" + dl.dataset.datalistfor+"]");
        let uniqVals = [];
        select.querySelectorAll('option:checked').forEach(so => {
          if (!uniqVals.includes(so.value)) {
            dl.insertAdjacentHTML('beforebegin',"<div class='datalist-selections' data-label='"+so.innerHTML+"' data-dlinput='"+dl.name+"' data-list='#"+dl.getAttribute("list")+"' data-select='#"+ft+"_"+dl.dataset.datalistfor+"' data-value='"+so.value+"' data-action='click->form#removeDatalistOption'>"+so.innerHTML+" &#9447;</div>");
            uniqVals.push(so.value); 
          }

          let dlSibling = dl.nextElementSibling
          if ((dlSibling.id == "makeslist") || (dlSibling.id == "part_idslist") || (dlSibling.id == "categorylist") || (dlSibling.id == "trcategorylist") || (dlSibling.id == "subjectslist") || (dlSibling.id == "unit_idslist") || (dlSibling.id == "r_tr_idslist") || (dlSibling.id == "required_partslist") || (dlSibling.id == "existing_toolslist") || (dlSibling.id == "existing_rec_toolslist") || (dlSibling.id == "reciprocal_related_partslist") || (dlSibling.id == "req_tool_idslist") || (dlSibling.id == "rec_tool_idslist") || (dlSibling.id == "req_part_idslist") || (dlSibling.id == "r_part_idslist")  || (dlSibling.id == "patentslist") || (dlSibling.id == "tech_resource_idslist") || (dlSibling.id == "fpartslist") || (dlSibling.id == "ftechlist") || (dlSibling.id == "fsymptomslist") ) {
            // let selections = Array.from(dlSibling.querySelectorAll('option')).filter(o => o.value == String(so.text));
            let selections = Array.from(dlSibling.querySelectorAll('option')).filter(o => o.value == String(so.innerHTML));
            selections.forEach(s => {s.disabled = true});
            if ( (dlSibling.id == "tech_resource_idslist") ) {
              let withoutampersan = so.innerHTML.replace(/amp;/g, "")
              let selections2 = Array.from(dlSibling.querySelectorAll('option')).filter(o => o.value == String(withoutampersan));
              selections2.forEach(s => {s.disabled = true});
            }
            if ( (dlSibling.id == "ftechlist") ) {
              let withoutampersan = so.innerHTML.replace(/amp;/g, "")
              let selections2 = Array.from(dlSibling.querySelectorAll('option')).filter(o => o.value == String(withoutampersan));
              selections2.forEach(s => {s.disabled = true});
            }
          }
          
        });
      } else {
        if(select) {
          const sv = select.querySelector("option[value='"+select.value+"']");
          if (sv) {
            if(dl.name == "symptoms__nameDataList") {
              dl.value = sv.innerHTML.replace(/amp;/g, "");
            }
            else {
              dl.value = sv.innerHTML;
            }
          }
        }

      }
    })
  }

  datalistChange(e) {
    const dl = e.target;
    const list = document.querySelector("#"+dl.getAttribute("list"));

    const selectedOption = list.querySelector("option[value='"+dl.value+"']");
    let ft = this.data.get('formtype') == "tsp_workflow" ? "tech_spec" : this.data.get('formtype');
    const select = dl.parentNode.querySelector("#"+ft+"_"+dl.dataset.datalistfor);
    if (!this.is_valid_datalist_value(dl.getAttribute("list"), dl.value)) {
      alert("Entered option not currently on list as written, or is already selected. Start typing again to see options. Contact marketing@sonnax.com to see about having something added to the list.")
      e.target.value = '';
    } else {
      if (selectedOption) {
        select.querySelector("option[value='"+selectedOption.dataset.value+"']").selected = true;
      }
      

      if (dl.dataset.datalisttype == "multiple" && dl.value != '') {
        const label = dl.parentNode.querySelector("label[for="+ft+"_" + dl.dataset.datalistfor+"]");
        dl.insertAdjacentHTML('beforebegin', "<div class='datalist-selections' data-label='"+dl.value+"' data-dlinput='"+dl.name+"' data-list='#"+dl.getAttribute("list")+"' data-select='#"+ft+"_"+dl.dataset.datalistfor+"' data-value='"+selectedOption.dataset.value+"' data-action='click->form#removeDatalistOption'>"+dl.value+" &#9447;</div>")
        selectedOption.disabled = true;
        dl.value = '';
      }

      if ((dl.getAttribute("list") == "unit_relationships__unit_idlist") || (dl.getAttribute("list") == "engineerlist") || (dl.getAttribute("list") == "userlist")) {
        this.updateHiddenInput(e);
      }
      let formName = this.data.get('formtype');
      if (formName == "tech_spec" && document.querySelector("form#new_tech_spec")) {
        this.tspNewPrep(e);
      }

      if (dl.getAttribute("list") == "dimensions__namelist") {
        this.dimensionValues(e, selectedOption);
      }
    }
    if (selectedOption) {
      select.querySelector("option[value='"+selectedOption.dataset.value+"']").selected = true;
    }
  }

  removeDatalistOption(e) {

    const so = e.target;
    const list = document.querySelector(so.dataset.list);
    const select = document.querySelector(so.dataset.select);

    //const dlSelectedOption = list.querySelector("option[value='"+so.dataset.label+"']");
    const dlSelectedOptions = list.querySelectorAll("option[value='"+so.dataset.label+"']");
    //const sSelectedOption = select.querySelector("option[value='"+so.dataset.value+"']");
    const sSelectedOptions = select.querySelectorAll("option[value='"+so.dataset.value+"']");
    sSelectedOptions.forEach(s => {s.selected = false});
    dlSelectedOptions.forEach(s => {s.disabled = false});

    so.parentNode.removeChild(so);
  }

  removeFormFields(e) {
    let ancestor = e.target.closest('[data-target="form.fieldsRow"]');
    ancestor.parentNode.removeChild(ancestor);
  }

  removeRemoteFormFields(e) {
    let destroyContainer = e.target.closest('[data-target="form.destroyField"]');

    let removeContainer = e.target.dataset.removetarget;

    let container = e.target.closest('[data-target="form.'+removeContainer+'"]');
    destroyContainer.querySelectorAll("input").forEach(input => {input.value = 1});
    container.classList.add("offscreen");
  }

  removeMultipleImage(e) {
    let img = e.target;
    let container = img.parentNode;
    container.querySelector("input").value = "";
    container.removeChild(img);
  }

  removeAllFiles(e) {
    let file = e.target;
    let container = file.parentNode;
    container.classList.add("hidden")
    let input = container.querySelector("input");
    input.name = "part[" + input.name;
    input.value = "";
  }

  removeNewFields(e){
    this.newDsFieldsTargets.forEach(row => row.remove());
  }

  dragstart(event) {
    event.target.classList.add("draggedItem");
    event.dataTransfer.setData('Text', event.target);
  }

  dragover(event) {
    event.preventDefault();
    return true
  }

  dragenter(event) {
    event.preventDefault();
  }

  drop(event) {
    var data = event.dataTransfer.getData("application/drag-key");
    const dropTarget = event.target.closest('[data-target="form.fieldsRow"]');
    const draggedItem = this.element.querySelector(".draggedItem");
    const positionComparison = dropTarget.compareDocumentPosition(draggedItem);
    if (positionComparison & 4) {
      dropTarget.insertAdjacentElement('beforebegin', draggedItem);
    } else if (positionComparison & 2) {
      dropTarget.insertAdjacentElement('afterend', draggedItem);
    }
    event.preventDefault()
  }

  dragend(event) {
    const draggedItem = this.element.querySelector(".draggedItem");
    let container = draggedItem.closest('[data-target="form.fieldsContainer"]');
    let inputs = container.querySelectorAll(".li-position input");
    inputs.forEach(function (input, index) {
      if (input.name.includes("position")) {
        input.value = index;
      }
    });
    draggedItem.classList.remove("draggedItem");
  }

  changedDatasheet() {
    let inputs = container.querySelectorAll("#ds_table .li-position input");
    inputs.forEach(function (input, index) {
      if (input.name.includes("position")) {
        input.value = index;
      }
    });
  }

  onRemoteSuccess(event) {
    let [data, status, xhr] = event.detail;
    let form = this.formTarget;
    let message = this.formTarget.dataset.message;
    let newEl = document.createElement('p');
    newEl.classList.add("beta");
    newEl.appendChild(document.createTextNode(message));
    form.parentNode.appendChild(newEl);
    form.parentNode.removeChild(form);
  }

  onPostError(event) {
    let [data, status, xhr] = event.detail;
    let form = this.formTarget;
    let newEl = document.createElement('p');
    newEl.appendChild(document.createTextNode(xhr.response));
    form.parentNode.appendChild(newEl);
  }

  updateTechResourceContent() {
    let new_content = this.trContentTarget.innerHTML;
    this.trContentInputTarget.value = new_content;
  }

  updateHiddenInput(e) {
    let updateValue = e.target.dataset.updatevalue;
    let inputValue = e.target.value.replace(new RegExp("~", "g"), "'");
    let inputText = e.target.nodeName == 'SELECT' ? (e.target.selectedOptions[0].textContent || e.target.selectedOptions[0].innerText) : e.target.hasAttribute("list") ? e.target.value : (e.target.textContent || e.target.innerText);
    let fieldName = e.target.dataset.updatefield;
    
    let row = e.target.closest('[data-target="form.fieldsRow"]');
    let hiddenInput = row.querySelectorAll('[data-target="form.hiddenInput"]')[0];
    if (updateValue == "value") {
      hiddenInput.value = inputValue;
    } else if (updateValue == "text") {
      hiddenInput.value = inputText.replace(' Transmission', '').replace(' Torque Converter', '');
    }
  }

  toggleHiddenInput(e) {
    let checkbox = e.target;
    let fieldName = checkbox.dataset.field;
    let row = e.target.closest('[data-target="form.fieldsRow"]');
    let hiddens = row.querySelectorAll('[data-target="form.hiddenInput"]'); 
    if (checkbox.checked == true) {
      for (let h of hiddens) {
        if (h.dataset.field == fieldName) {
          h.classList.remove("hidden");
        }
      }
    } else {
      for (let h of hiddens) {
        if (h.dataset.field == fieldName) {
          h.classList.add("hidden");
        }
      }
    }
  }

  revealAllHiddenFields(e) {
    let optionalFields = document.querySelectorAll('.optional-field');
    optionalFields.forEach(f => {f.classList.remove('hidden-field')});
  }

  checkLength(e) {
    let inputs = this.specificLengthTargets;
    for (let area of inputs) {
      if (area.value.length > 70) {
        area.style.cssText += 'border: 2px solid red';
      } else if (area.value.length > 35) {
        area.style.cssText += 'border: 2px solid orange';
      } 
    }
  }

  tspNewPrep(e) {
    let miniline = document.getElementById('tech_spec_miniline').value;
    let category = document.getElementById('tech_spec_category');
    let hp = document.getElementById('tech_spec_is_hp');
    let partType = document.getElementById('tech_spec_part_types');
    
    if (miniline == 11 || miniline == 6 || miniline == 10 || miniline == 13 || miniline == 12) {
      category.value = 3
    }
    if (miniline == 2 || miniline == 3){
      category.value = 2
    }
    if (miniline == 4){
      category.value = 10
    }
    if (miniline == 5){
      category.value = 1
    }
    if (miniline == 7){
      category.value = 11
    }
    if (miniline == 9){
      category.value = 9
    }
    if (miniline == 3 || miniline == 6 || miniline == 5){
      hp.checked = true;
    }
    if (miniline == 13) {
      partType.value = "Valve Body";
      partType.dispatchEvent(new Event('change'));
    }
  }

  changedPart(e) {
    let formName = e.target.name.split('[')[0];

    // Only execute for the "part" form
    if (formName === "part" || formName === "tech_spec") {
      if (e.target.id === `${formName}_category`) {
          const selectedCategory = parseInt(e.target.value, 10);

          // Select the correct elements
          const areaSelect = document.querySelector(`#${formName}_area_of_transmission`);
          const hiddenField = document.querySelector(`#hidden_${formName}_area_of_transmission`);

          if (selectedCategory === 3) {
              // If the category is "Transmission" (3)
              if (areaSelect) {
                  areaSelect.classList.remove("hidden-field"); // Ensure it's visible
              }
              if (hiddenField) {
                  hiddenField.value = ""; // Do not set a null value
              }
          } else {
              // For all other categories
              if (areaSelect) {
                  // Safely check if options exist
                  const options = areaSelect.options;
                  if (options) {
                      Array.from(options).forEach(option => {
                          option.removeAttribute("selected");
                      });
                  }

                  areaSelect.value = ""; // Reset the selection
                  areaSelect.classList.add("hidden-field"); // Hide the field
              }
              if (hiddenField) {
                  hiddenField.value = null; // Explicitly set to null
              }
          }
          if (formName === "tech_spec") {
            if (selectedCategory !== 2) {
              document.querySelectorAll('.cat-2-only').forEach(c => {c.classList.add('hidden-field')});
            } 
          }
      }
    }

    this.prepPart(this.data.get('formtype'));
  }

  prepPart(formName) {
    let category
    let miniline = document.querySelector('select#'+formName+'_miniline').value;
    if (document.querySelector('select#'+formName+'_category')) {
      category = document.querySelector('select#'+formName+'_category').value;
    }
    category = typeof(category) == 'undefined' ? 0 : category;
    let partType = document.querySelector('select#'+formName+'_part_types').value;
    let hp = false;
    let hpField = '';
    let nameField = '';
    let numberField = '';

    if (formName == 'tech_spec') {
      nameField = 'part_name';
      numberField = 'part_number';
      hp = document.querySelector('#'+formName+'_is_hp').checked;
      hpField = 'is_hp';
    } else {
      nameField = 'name';
      numberField = 'number';
      hp = document.querySelector('#'+formName+'_high_performance').checked;
      hpField = 'high_performance';
    }

    let desc = "";
    
    for (let t of document.querySelectorAll('textarea')) {
      let inputText = t.textContent || t.innerText;
      desc += ' ' + inputText
    }
    for (let input of document.querySelectorAll('input')) {
      let inputText = String(input.value);
      desc += ' ' + inputText
    }

    desc = desc.toLowerCase();

    if (category == 1) {
      document.querySelector('#'+formName+'_'+hpField).checked = true;
      let hp = true;
    }

    if (category == 2 || category == 3) {
      document.querySelectorAll('.cat-2').forEach(c => {c.classList.remove('hidden-field')})
    } else {
      document.querySelectorAll('.cat-2').forEach(c => {c.classList.add('hidden-field')}) 
    }

    if (category != 3) {
      document.querySelectorAll('.cat-3').forEach(c => {c.classList.add('hidden-field')});
      document.querySelectorAll('.cat-3-conditional').forEach(c => {c.classList.add('hidden-field')});
    } else {
      document.querySelectorAll('.cat-3').forEach(c => {c.classList.remove('hidden-field')});
      document.querySelectorAll('.cat-3-conditional').forEach(c => {c.classList.remove('hidden-field')});
      if (hp == true || partType == 'Valve Body' || partType == 'Tool'){
        document.querySelectorAll('.cat-3-conditional').forEach(c => {c.classList.add('hidden-field')});
      }
      else {
        document.querySelectorAll('.cat-3-conditional').forEach(c => {c.classList.remove('hidden-field')});
      }
    }

    if (category == 2) {
      document.querySelectorAll('.cat-2-only').forEach(c => {c.classList.remove('hidden-field')});
    } else {
      document.querySelectorAll('.cat-2-only').forEach(c => {c.classList.add('hidden-field')});
    }

    if (category > 9 || partType == "Friction Ring") {
      document.querySelectorAll('.'+formName+'_description, .add_kit_component').forEach(c => {c.classList.add('hidden-field')});
    }
    else {
      document.querySelectorAll('.'+formName+'_description, .add_kit_component').forEach(c => {c.classList.remove('hidden-field')});
    }

    if (hp == true) {
      document.querySelectorAll('.hp-only').forEach(c => {c.classList.add('hidden-field')});
      document.querySelectorAll('.break-out-main').forEach(c => {c.classList.add('break-out-hp')});
    } else {
      document.querySelectorAll('.hp-only').forEach(c => {c.classList.remove('hidden-field')});
      document.querySelectorAll('.break-out-main').forEach(c => {c.classList.remove('break-out-hp')});
    }
    if (partType != 'Valve Body') {
      $('.type-VB').addClass('hidden-field');
      document.querySelectorAll('.type-VB').forEach(c => {c.classList.add('hidden-field')});
    }
    else {
      $('.type-VB').removeClass('hidden-field');
      document.querySelectorAll('.type-VB').forEach(c => {c.classList.remove('hidden-field')});
    }

    if ((document.getElementById(formName+'_vb_fix').checked == true) && (!document.getElementById(formName+'_'+nameField).value.toLowerCase().includes("oversized")) && (partType != 'Tool')) {
      document.getElementById('vbFixWarning').style.display = 'block';
    } else {
      document.getElementById('vbFixWarning').style.display = 'none';
    }

    if ((document.getElementById(formName+'_'+nameField).value.toLowerCase().includes("fixes")) || (document.getElementById(formName+'_'+nameField).value.toLowerCase().includes("repairs"))) {
      document.getElementById('partNamewarning_fixes').style.display = 'block';
    } else {
      document.getElementById('partNamewarning_fixes').style.display = 'none';
    }

    if ((desc.includes("now")) || (desc.includes("now"))) {
      document.getElementById('partNowOffers').style.display = 'block';
    }
    else {
      document.getElementById('partNowOffers').style.display = 'none';
    }

    if (desc.includes("valve is manufactured to extremely tight tolerances")) {
      document.getElementById('tightTolerances').style.display = 'block';
    }
    else {
      document.getElementById('tightTolerances').style.display = 'none';
    }

    if (desc.includes("oem")) {
      document.getElementById('oem').style.display = 'block';
    }
    else {
      document.getElementById('oem').style.display = 'none';
    }

    if ((desc.includes("falling out of lockup hot")) || (desc.includes("falls out of lockup hot"))) {
      document.getElementById('fallsOutOfLockup').style.display = 'block';
    }
    else {
      document.getElementById('fallsOutOfLockup').style.display = 'none';
    }

    if ((desc.includes(" u.s.a. ")) || (desc.includes(" us "))) {
      document.getElementById('usa_styling').style.display = 'block';
    }
    else {
      document.getElementById('usa_styling').style.display = 'none';
    }

    if ((desc.includes("valve is hard-coat anodized")) || (desc.includes("valve has a hard anodized finish")) || (desc.includes("Valves are hardcoat anodized for extended life"))) {
      document.getElementById('hardCoat').style.display = 'block';
    }
    else {
      document.getElementById('hardCoat').style.display = 'none';
    }

    if (desc.includes("teflon")) {
      document.getElementById('teflon_notice').style.display = 'block';
    }
    else {
      document.getElementById('teflon_notice').style.display = 'none';
    }

    if (partType == 'Slip Yoke') {
      let traits = document.querySelectorAll('#trait_fields select');
      traits.forEach(t => {
        if (t.value == 'Material') {
          let row = t.closest('[data-target="form.fieldsRow"]');
          let materialValue = row.querySelector("#"+formName+"_traits__value").value;
          if (!materialValue.includes("Forged")) {
            document.getElementById('slipyokeWarning').style.display = 'block';
          }
          else {
            document.getElementById('slipyokeWarning').style.display = 'none';
          }
        }
      });
    }

    if (formName == "tech_spec") {
      if (document.querySelector("#tech_spec_instructions_required").checked == true) {
        document.querySelectorAll('.in-req').forEach(c => {c.classList.remove('hidden-field')});
      }
      else {
        document.querySelectorAll('.in-req').forEach(c => {c.classList.add('hidden-field')});
      }

      if (document.querySelector("#tech_spec_quality_report_required").checked == true) {
        document.querySelectorAll('.qr-req').forEach(c => {c.classList.remove('hidden-field')});
      }
      else {
        document.querySelectorAll('.qr-req').forEach(c => {c.classList.add('hidden-field')});
      }

      let ftool_count = 0;
      let newTools = this.fieldsContainerTargets.filter(r => r.dataset.field == 'new_tool');

      let vb_fix = document.getElementById('tech_spec_vb_fix').checked;

      let newToolUl = newTools[0];
      const newToolLis = newToolUl.querySelectorAll('.card-wide');
         
      for (let newTool of newToolLis) {     

        if (newTool.querySelector('.tech_spec_new_tools_tool_part_number input').value.startsWith("F-")) {
          ftool_count += 1;
        }
        if (newTool.querySelector('#tool_recommended_fake').checked == true) {
          newTool.querySelector("#tech_spec_new_tools__tool_recommended").value = "1";
        } else {
          newTool.querySelector("#tech_spec_new_tools__tool_recommended").value = "0";
        }
        if (newTool.querySelector('#tool_vb_fix_fake').checked == true) {
          newTool.querySelector("#tech_spec_new_tools__tool_vb_fix").value = "1";
        } else {
          newTool.querySelector("#tech_spec_new_tools__tool_vb_fix").value = "0";
        }
        if (newTool.querySelector('#tool_servo_fix_fake').checked == true) {
          newTool.querySelector("#tech_spec_new_tools__tool_servo_fix").value = "1";
        } else {
          newTool.querySelector("#tech_spec_new_tools__tool_servo_fix").value = "0";
        }
      }

      let existingToolNumbers = Array.from(document.querySelectorAll('#tech_spec_existing_tools option:checked')).map(el => el.value);
      for (let eTool of existingToolNumbers) {
        if (eTool.startsWith('F-')) {
          ftool_count += 1;
        }
      }
      if (!vb_fix && ftool_count > 0) {
        document.getElementById('vbfix_notice').style.display = 'block';
      } else {
        document.getElementById('vbfix_notice').style.display = 'none';
      }

      for (let bullet of document.querySelectorAll('#qr_bullet_fields textarea:not(:disabled)')) {
        let row = e.target.closest('[data-target="form.fieldsRow"]');
        row.querySelector("input[type='checkbox']").value = bullet.value;
      }

      for (let input of document.querySelectorAll('.tech_spec_final_testing_info_option .radio input')) {
        if (input.checked == true) {
          let fti_content = document.getElementById('fti_content');
          let lab = input.closest('label').closest('.radio');
          if (input.value == "Other") {
            document.querySelector('#fti_content label').textContent = "";
          } else {
            document.querySelector('#fti_content label').textContent = "Minimum in-Hg";
          }
          lab.appendChild(fti_content);
          fti_content.classList.remove('offscreen');
        }
      }
    }
  }


  youNeedThisIf(e) {
    let val = e.target.value;
    let defaultContent = "Vacuum testing at the port(s) indicated fails to hold the recommended minimum in-Hg, or if wear is visually detected.";
    let neededWhen = document.getElementById('tech_spec_needed_when');
    if (val == "other") {
      neededWhen.value = "";
      neededWhen.classList.remove("offscreen");
    } else {
      neededWhen.value = defaultContent;
      neededWhen.classList.add("offscreen");
    }
  }

  anatomyOfATransmission(e) {
    let sympValue = e.target.value.toLowerCase();
    let str = document.querySelector(".anatomy-symptoms").innerText.toLowerCase();
    let select = document.getElementById("part_tech_resource_ids");
    let currentTech = Array.from(document.querySelectorAll('#part_tech_resource_ids option:checked')).map(el => el.value);
    if ((!currentTech.includes("131")) && (str.includes(sympValue))) {
      // let lastInnerHTML = select.innerHTML;
      // lastInnerHTML += '<option value="131" selected="selected">Anatomy of a Transmission</option>'
      // select.innerHTML = lastInnerHTML
      select.querySelector("option[value='131']").selected = true;
      var event = new Event('change');
      select.dispatchEvent(event);
      const list = document.querySelector("#tech_resource_idslist");
      const selectedOption = list.querySelector("option[value='131']");
      const inputRelatedTechResources = document.getElementById("input_related_tech_resources");
      inputRelatedTechResources.insertAdjacentHTML('beforebegin', "<div class='datalist-selections' data-label='Anatomy of a Transmission: Oil Flow in the Pump-PR-Converter-Cooler Lube Circuits' data-dlinput='tech_resource_idsDataList' data-list='#tech_resource_idslist' data-select='#part_tech_resource_ids' data-value='131' data-action='click->form#removeDatalistOption'>'Anatomy of a Transmission: Oil Flow in the Pump-PR-Converter-Cooler Lube Circuits' &#9447;</div>")
      selectedOption.disabled = true;
      inputRelatedTechResources.value = '';
      var eventInput = new Event('change');
      inputRelatedTechResources.dispatchEvent(eventInput);
    }
    
  }

  leaveWarning(e) {
    event.preventDefault();
    let warning = e.target.dataset.warning;
    let r = confirm(warning);
    if (r == true)   {  
      window.location = e.target.getAttribute('href');
    }
  }

  addCustomSelectOption(e) {
    let selects = document.querySelectorAll('#trait_fields select, #dimension_fields select');
    for (let s of selects) {  
      let newOption = document.createElement('option');
      newOption.value = "custom";
      newOption.innerHTML = "Not on List";
      s.insertBefore(newOption, s.firstChild);
    }
  }

  addCustomField(e) {
    let val = e.target.value;
    let select_name = e.target.getAttribute("name");
    if (val == "custom") {
      let row = e.target.closest('[data-target="form.fieldsRow"]');
      let container = row.querySelectorAll('[data-target="form.customFieldContainer"]')[0];
      let choiceDiv = e.target.closest('.choices');
      choiceDiv.innerHTML = "";
      choiceDiv.remove();
      container.innerHTML = '<div class="input string required"><label class="string required disabled" for="'+select_name+'"><abbr title="required">*</abbr> Name</label><input class="string required" type="text" name="'+select_name+'"></div>';
    }
  }

  hideColumn(e) {
    let li = e.target;
    let ul = e.target.closest("ul");
    let nodes = Array.prototype.slice.call(ul.children);
    let li_index = nodes.indexOf(li);
    let cells = document.querySelectorAll('#ds_table th, #ds_table td');
    
    li.classList.toggle("hidden-column");
    this.setColIndex();

    for (let cell of cells) {
      let tr = cell.closest("tr");
      let col_index = cell.dataset.colindex;

      if ((col_index == li_index) && (!hasClass(tr, 'header_row'))) {
        cell.classList.toggle("hidden");
      }
    }

    function hasClass(el, className) {
      return el.classList ? el.classList.contains(className) : new RegExp('\\b'+ className+'\\b').test(el.className);
    }
  }

  setColIndex() {
    let trs = Array.prototype.slice.call(document.querySelectorAll('#ds_table tr'));
    for (let tr of trs) {
      let allCells = tr.children;
      let baseIndex = 0;
      Array.from(allCells).forEach(function (c, index) {
        let span = c.getAttribute('colspan');
        let cellIndex = 0;

        if (span) {
          cellIndex += parseInt(span);
        } else {
          cellIndex += 1;
        }

        c.setAttribute("data-colindex", baseIndex);
        baseIndex += cellIndex;
      });
    }
  }

  newToolComponents(e) {
    for (let newTool of this.newToolComponentsTargets) {
      let new_tool_components = '';
      for (let row of newTool.querySelectorAll('[data-target="form.fieldsRow"]')) {
        new_tool_components += (row.querySelectorAll('[data-field="new_tool_component_name"]')[0].value + '$');
        new_tool_components += (row.querySelectorAll('[data-field="new_tool_component_quantity"]')[0].value + "|");
      }
      newTool.querySelectorAll('[data-target="form.newToolInput"]')[0].value = new_tool_components;
    }
  }

  featuredUnitVisibility(e) {
    const fields = document.getElementById("featuredFields");
    if (e.target.checked == true){
      fields.classList.remove("hidden")
    } else {
      fields.classList.add("hidden")
    }
  }

  checkEmailField(email) {
    const val = email.target.value;
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(val) != true) {
      alert('\"'+val+'\" is not a valid email address.')
    }
  }
  
  loadData(url, callback) {
    fetch(url)
      .then(response => {return response.json();})
      .then(json => {callback.call(this, json); return json})
  }

  dimensionValues(e, select) {
    let formName = this.data.get('formtype');
    let row = e.target.closest('[data-target="form.fieldsRow"]');
    if (select) {
      row.querySelector("#"+formName+"_dimensions__precision").value = select.dataset.customProperties.split('|')[0];
      if (select.dataset.customProperties.split('|')[1] != "null") {
        row.querySelector("#"+formName+"_dimensions__default_unit").value = select.dataset.customProperties.split('|')[1];
      } else {
        row.querySelector("#"+formName+"_dimensions__default_unit").value = "";
      }
    }
    else {
      let custom_properties = e.target.selectedOptions[0].dataset.customProperties;
      let myPrecision = custom_properties.split('|')[0];
      let myUnit = custom_properties.split('|')[1];
      row.querySelector("#"+formName+"_dimensions__precision").value = myPrecision;
      if (myUnit != "null") {
        row.querySelector("#"+formName+"_dimensions__default_unit").value = myUnit;
      } else {
        row.querySelector("#"+formName+"_dimensions__default_unit").value = "";
      }
    }
  }

  loadSelects(data) {
    let formName = this.data.get('formtype');
    let singleselects = [];
    let multiselects = [];

    if(formName == "tech_resource") { 
      this.initializeTechResourcesUnitRelationshipsFields(data);
      return
    }

    for (let collection in data) {
      let collectionData = JSON.parse(data[collection]).data;
      let collectionSelects = this.jsDrivenSelectTargets.filter(r => r.dataset.jsontype == collection);

      let items = "";
      let dlitems = "";
      for (let d of collectionData) {
        items += this.createOption(d, collection);
        dlitems += this.createDlOption(d, collection);
      }

      if (formName == "tech_spec" && collection == "parts") {
        let tspData = JSON.parse(data["tsps"]).data;
        for (let d of tspData) {
          const optionTsp = this.createOption(d, "tsps")
          const found = items.includes(optionTsp)
          if (!found) {
            items += this.createOption(d, "tsps");
            dlitems += this.createDlOption(d, "tsps");
          }
        }
      }

      if(formName == "tech_spec") { 
        if ((collection == "traits") || (collection == "symptoms") || (collection == "units") || (collection == 'dimensions') || (collection == 'trs') ) {
          collectionSelects.forEach(select => {
            let currentValue = select.dataset.currentvalue;
            const placeholder = (collection == "traits") ? `<option  value="" disabled selected hidden>Select Option</option>` : `<option></option>` 
            const itemsWithPlaceholder = placeholder + items
            select.insertAdjacentHTML("afterbegin", itemsWithPlaceholder);
            let selections = Array.from(select.querySelectorAll('option')).filter(o => o.value == String(currentValue));
            selections.forEach(s => {
                s.selected = true;
                if (collection == 'dimensions') {
                  if (s.value == ""){
                  }
                  else {
                    let dimensionRow = select.closest("li");
                    let precisionInput = dimensionRow.querySelector("#"+formName+"_dimensions__precision");
                    let unitInput = dimensionRow.querySelector("#"+formName+"_dimensions__default_unit");
                    precisionInput.value = s.dataset.customProperties.split('|')[0];
                    unitInput.value = s.dataset.customProperties.split('|')[1];
                  }
                }
                if (collection == 'units') {
                  let categoryInput = document.getElementById(formName+'_category');
                  if (categoryInput.value == 2) {
                    let transmissions = Array.from(select.querySelectorAll('option')).filter(o => o.dataset.customProperties == "Transmission");
                    transmissions.forEach(t => {select.removeChild(t);});
                    
                  } else if (categoryInput.value == 3) {
                    let converters = Array.from(select.querySelectorAll('option')).filter(o => o.dataset.customProperties == "Torque Converter");
                    converters.forEach(t => {select.removeChild(t);});
                  }
                }
            });
          });

          continue;
        }
      }

      if(formName == "part") { 
        if ((collection == "traits") || (collection == "symptoms") || (collection == "units") || (collection == 'dimensions')) 
        {
          collectionSelects.forEach(select => {
            let currentValue = select.dataset.currentvalue;
            const placeholder = (collection == "traits") ? `<option  value="" disabled selected hidden>Select Option</option>` : `<option></option>` 
            const itemsWithPlaceholder = placeholder + items
            select.insertAdjacentHTML("afterbegin", itemsWithPlaceholder);
            let selections = Array.from(select.querySelectorAll('option')).filter(o => o.value == String(currentValue));
            selections.forEach(s => {
                s.selected = true;
                if (collection == 'dimensions') {
                  if (s.value == ""){
                  }
                  else {
                    let dimensionRow = select.closest("li");
                    let precisionInput = dimensionRow.querySelector("#"+formName+"_dimensions__precision");
                    let unitInput = dimensionRow.querySelector("#"+formName+"_dimensions__default_unit");
                    precisionInput.value = s.dataset.customProperties.split('|')[0];
                    unitInput.value = s.dataset.customProperties.split('|')[1];
                  }
                }
                if (collection == 'units') {
                  let categoryInput = document.getElementById(formName+'_category');
                  if (categoryInput.value == 2) {
                    let transmissions = Array.from(select.querySelectorAll('option')).filter(o => o.dataset.customProperties == "Transmission");
                    transmissions.forEach(t => {select.removeChild(t);});
                    
                  } else if (categoryInput.value == 3) {
                    let converters = Array.from(select.querySelectorAll('option')).filter(o => o.dataset.customProperties == "Torque Converter");
                    converters.forEach(t => {select.removeChild(t);});
                  }
                }
            });
          });

          continue;
        }
      }

      if(formName == "tech_resource") { 
        if (collection == "units") 
        {
          collectionSelects.forEach(select => {
            let currentValue = select.dataset.currentvalue;
            const placeholder =  `<option></option>` ;
            const itemsWithPlaceholder = placeholder + items
            select.insertAdjacentHTML("afterbegin", itemsWithPlaceholder);
            let selections = Array.from(select.querySelectorAll('option')).filter(o => o.text == String(currentValue));
            selections.forEach(s => {
                s.selected = true;

            });
          });

          continue;
        }
      }
      
      collectionSelects.forEach(select => {
        let currentValue = select.dataset.currentvalue;

        select.insertAdjacentHTML("afterbegin", items);
        
        if (select.multiple == true && (currentValue && currentValue.length > 0)) {
          for (let v of JSON.parse(currentValue)) {
            let selections = Array.from(select.querySelectorAll('option')).filter(o => o.value == String(v));
            selections.forEach(s => {s.selected = true});
          }
        } else {
          let selections = Array.from(select.querySelectorAll('option')).filter(o => o.value == String(currentValue));
          selections.forEach(s => {
            s.selected = true;
            if (collection == 'dimensions') {
              if (s.value == ""){

              }
              else {
                let dimensionRow = select.closest("li");
                let precisionInput = dimensionRow.querySelector("#"+formName+"_dimensions__precision");
                let unitInput = dimensionRow.querySelector("#"+formName+"_dimensions__default_unit");
                precisionInput.value = s.dataset.customProperties.split('|')[0];
                unitInput.value = s.dataset.customProperties.split('|')[1];
              }
            }
            if (collection == 'units') {
              let categoryInput = document.getElementById(formName+'_category');
              if (categoryInput.value == 2) {
                let transmissions = Array.from(select.querySelectorAll('option')).filter(o => o.dataset.customProperties == "Transmission");
                transmissions.forEach(t => {select.removeChild(t);});
                
              } else if (categoryInput.value == 3) {
                let converters = Array.from(select.querySelectorAll('option')).filter(o => o.dataset.customProperties == "Torque Converter");
                converters.forEach(t => {select.removeChild(t);});
              }
            }
          });
          
        }
        if (select.dataset.nested != "true") {
          select.removeAttribute('disabled');
        }
        if (select.nextSibling) {
          if (select.nextSibling.nextElementSibling && select.nextSibling.nextElementSibling.hasAttribute('list')) {
            const dl = select.nextSibling.nextElementSibling;
            const list = document.querySelector("#"+dl.getAttribute("list"));
            if (list && list.innerHTML.length < 1) {

              if(collection == "types") {
                list.insertAdjacentHTML("afterbegin", dlitems);
                for (let v of JSON.parse(currentValue)) {
                  let selections = Array.from(list.querySelectorAll('option')).filter(o => o.value == String(v));
                selections.forEach(s => {s.disabled = true});
                }

              }
              else {
                list.insertAdjacentHTML("afterbegin", dlitems);
              }
              
            }
            if (collection == 'units') {
              let categoryInput = document.getElementById(formName+'_category');
              if (categoryInput.value == 2) {
                let transmissions = Array.from(list.querySelectorAll('option')).filter(o => o.dataset.customProperties == "Transmission");
                transmissions.forEach(t => {list.removeChild(t);});
                
              } else if (categoryInput.value == 3) {
                let converters = Array.from(list.querySelectorAll('option')).filter(o => o.dataset.customProperties == "Torque Converter");
                converters.forEach(t => {list.removeChild(t);});
              }
            }
          }
        } else if (select.parentNode.nextSibling) {
          if (select.parentNode.nextSibling.nextElementSibling && select.parentNode.nextSibling.nextElementSibling.hasAttribute('list')) {
            
            const dl = select.parentNode.nextSibling.nextElementSibling;
            const list = document.querySelector("#"+dl.getAttribute("list"));
            if (list.innerHTML.length < 1) {
              list.insertAdjacentHTML("afterbegin", dlitems);
            }
            
          }
        }
      });
        
      
        
    }
    this.initializeDatalists(this.datalistInputTargets);

    if (formName == "tech_spec" && document.querySelector("form#new_tech_spec")) {
      this.tspNewPrep();
    } else {
      this.prepPart(formName);
    }
    
  }

  createDlOption(d, collection) {
    let value = "";
    let label = "";
    let formName = this.data.get('formtype');
    switch (collection) {
        case 'symptoms':
          value = "escaped_name";
          label = "name";
          break;
        case 'types':
        case 'traits':
        case 'dimensions':
          value = "name";
          label = "name";
          break;
        case 'datasheets':
        case 'units':
          value = "id";
          label = "name";
          break;
        case 'parts':
          if (formName == "tech_spec") {
            value = "number";
            label = "number";
          } else {
            value = "id";
            label = "number";
          }
          break;
        case 'tsps':
          value = "part_number";
          label = "part_number";
          break;
        case 'vbls':
        case 'trs':
          value = "id";
          label = "title";
          break;
        case 'patents':
          value = "patent";
          label = "patent";
      };
    label = label.replace("'","~");
    const customs = (collection == 'dimensions') ? d.attributes.precision+'|'+d.attributes.default_unit : (collection == 'units') ? d.attributes.category : '';
    const option = `<option data-custom-properties="${customs}" value='${d.attributes[label].replace(new RegExp("\'", "g"), "~")}' data-value="${d.attributes[value]}"></option>`
    return option
  }


  createOption(d, collection) {
    let value = "";
    let label = "";
    let category = "";
    let formName = this.data.get('formtype');
    if(formName == "tech_resource") { 
      if (collection == "units"){
        value = "id";
        label = "name";
        category = "category";
        let value2 = d.attributes[value];
        let label2 =d.attributes[label];
        let label3 =d.attributes[category];
        let option2 = "<option data-custom-properties='' value=" + value2 + ">" + label2 + " " + label3 + "</option>";
        
        return option2
      }

    }
    switch (collection) {
        case 'symptoms':
          value = "escaped_name";
          label = "name";
          break;
        case 'types':
        case 'traits':
        case 'dimensions':
          value = "name";
          label = "name";
          break;
        case 'datasheets':
        case 'units':
          value = "id";
          label = "name";
          break;
        case 'parts':
          if (formName == "tech_spec") {
            value = "number";
            label = "number";
          } else {
            value = "id";
            label = "number";
          }
          break;
        case 'tsps':
          value = "part_number";
          label = "part_number";
          break;
        case 'vbls':
        case 'trs':
          value = "id";
          label = "title";
          break;
        case 'patents':
          value = "patent";
          label = "patent";
      };
    const customs = (collection == 'dimensions') ? d.attributes.precision+'|'+d.attributes.default_unit : (collection == 'units') ? d.attributes.category : '';
    const option = `<option data-custom-properties="${customs}" value="${d.attributes[value]}">${d.attributes[label]}</option>`
    return option
  }

  is_valid_datalist_value(idDataList, inputValue) {
    var option = document.querySelector("#" + idDataList + " option[value='" + inputValue + "']");
    var disableOptions = document.querySelectorAll("#" + idDataList + " option[disabled]" );
    if (option != null) {
      for (let optionDisable of disableOptions) {
        if(option.value == optionDisable.value) {
          return false
        }
      }
      return option.value.length > 0;
    }
    return false;
  }

  addFormFieldsNewTool(e) {
  
    let fieldName = e.target.id.replace('add_', '');

    let newRow = this.newFieldsTargets.filter(r => r.dataset.field == fieldName)[0];
    let container = this.fieldsContainerTargets.filter(c => c.dataset.field == fieldName)[0];
    let foo = newRow.cloneNode(true);
    foo.removeAttribute("id");
    foo.setAttribute('data-target', "form.fieldsRow");
    for (let input of foo.querySelectorAll("select, input, textarea, label, div")) {  
      input.removeAttribute('disabled');
      input.classList.remove("disabled");
    }

    this.initializeDatalists(this.datalistInputTargets);
    container.appendChild(foo);
    tippy('.tippy-new-tool-components', {
      content: "<p style='font-family: Helvetica; font-weight: 100; font-size: 1em;'>​List components in order of importance. If the item is not a kit, then NO components should be listed (example: reamers sold separately are not kits).</p>",
      allowHTML: true,
      placement: 'right',
      theme: 'sonnax',
    }); 
    tippy('.tippy-tool-notes', {
      content: "<p style='font-family: Helvetica; font-weight: 100; font-size: 1em;'>If the new tool part number starts with the letter 'F-', add the following to the note field: “Sonnax F-Tool kits designed to service a specific bore require the [VB-FIX], a self-aligning valve body reaming fixture.”</p>",
      allowHTML: true,
      placement: 'right',
      theme: 'sonnax',
    }); 

  }

  addFormFieldsToolComponents(e) {

    let fieldName = e.target.id.replace('add_', '');

    let newRow = this.newFieldsTargets.filter(r => r.dataset.field == fieldName)[0];

    let container = e.target.previousElementSibling;

    let foo = newRow.cloneNode(true);
    foo.removeAttribute("id");
    foo.setAttribute('data-target', "form.fieldsRow");
    for (let input of foo.querySelectorAll("select, input, textarea, label, div")) {  
      input.removeAttribute('disabled');
      input.classList.remove("disabled");
    }
    container.appendChild(foo);

  }

  removeFormFieldsToolComponents(e) {
    let ancestor = e.target.closest('[data-target="form.fieldsRow"]');
    ancestor.parentNode.removeChild(ancestor);
    this.newToolComponents(e);
  }

  updateEngName(e) {
    let targetValue = e.target.value;
    let innerEngName = null;
    for (let i = 0; i < e.target.options.length; i++) {
      const option = e.target.options[i];
      if (option.value === targetValue) {
        innerEngName  = option.innerText; break;
      }}
    if (innerEngName === "Select Value") {
      innerEngName = "";}
    let row = e.target.closest('[data-target="form.fieldsRow"]');
    let hiddenInput = row.querySelectorAll('[data-target="form.hiddenInput"]')[0];
    hiddenInput.value = innerEngName;
  }

  updateUnitName(e) {
    console.log("update Unit Name");
    let targetValue = e.target.value;
    let innerUnitName = null;
    for (let i = 0; i < e.target.options.length; i++) {
      const option = e.target.options[i];
      if (option.value === targetValue) {
        innerUnitName  = option.innerText; break;
      }}
    if (innerUnitName === "Select Value") {
      innerUnitName = "";}
    let row = e.target.closest('[data-target="form.fieldsRow"]');
    let hiddenInput = row.querySelectorAll('[data-target="form.hiddenInput"]')[0];
    hiddenInput.value = innerUnitName;
  }


  initializeTechResourcesUnitRelationshipsFields(data) {

    let formName = this.data.get('formtype');

    const select = document.querySelector("#tech_resource_unit_ids");
    let ul = document.getElementById("ulUnitRelationship");
    let uniqVals = [];
    let i = 0;
    select.querySelectorAll('option:checked').forEach(so => {
      if (!uniqVals.includes(so.value)) {
        let li = document.createElement('li');
        li.className = "exisitngunit_relationship row";
        li.style = "margin-bottom: 0px;";
        li.dataset.target = "form.fieldsRow";

        let mySelect = document.createElement('select');
        mySelect.className = "select";
        mySelect.style = "max-width: 250px;";

        mySelect.id = "tech_resource_unit_rel" + i + "";
        mySelect.name = "tech_resource_unit_rel" + i + "";
        mySelect.dataset.target = "form.jsDrivenSelectUnitRelationship";
        mySelect.dataset.currentvalue = so.text;
        mySelect.dataset.jsonvalue = "id";
        mySelect.dataset.action = "change->form#updateTechResourceUnitRelationship";
        mySelect.dataset.jsontype = "units";

        let myDivSelect = document.createElement('div')
        myDivSelect.style = "margin-bottom: 0px; min-width: 250px";
        myDivSelect.appendChild(mySelect);

        let myDivFeature = document.createElement('div')
        myDivFeature.style = "margin-left: 20px;";
        myDivFeature.innerHTML = "<div style='display: flex; flex-direction: row; padding-top: 5px;'><div style='padding-top: 3px;'><input  type='checkbox' style='margin-bottom: 0px;' ></div><div style='padding-bottom: 4px;'><label style='font-weight: bold'>Featured</label></div><div class='tooltip'><span style='margin-left: 10px'><svg class='info-mark icon-circle-info-solid'><use xlink:href='#circle-info-solid'></use></svg></span><span class='tooltip-text' style='top: -20px; margin-left: 10px;'>This field is ONLY for use by Marketing. Turning on 'Featured' status will cause an enabled product to be displayed as 'Featured' on the associated unit page.</span></div><div style='padding-left: 20px; '></div></div> ";
        
        let myLinkRemove = document.createElement('a')
        myLinkRemove.className  = "remove_fields";
        myLinkRemove.id = i ;
        myLinkRemove.href = "javascript:;";
        myLinkRemove.style = "margin-top: 2px; margin-left: 15px;";
        myLinkRemove.dataset.action = "form#removeFormFieldsTechResourceUnitRelationship";
        myLinkRemove.innerHTML= "x";

        let myDivRemove = document.createElement('div')
        myDivRemove.style = "margin-bottom: 0px; margin-left: 0px";
        myDivRemove.appendChild(myLinkRemove);

        li.appendChild(myDivSelect);
        //li.appendChild(myDivFeature);
        li.appendChild(myDivRemove);
        ul.appendChild(li);
        uniqVals.push(so.value); 

        $('#' + "tech_resource_unit_rel" + i).select2({
          placeholder: "Select option"
        });
        $('#' + "tech_resource_unit_rel" + i).on('select2:select', function() {
          let event = new Event('change', {bubbles: true})
          this.dispatchEvent(event);
        })

        i++;
      }
    });

    for (let collection in data) {
      if (collection == "units"){
      let collectionData = JSON.parse(data[collection]).data;
      let collectionSelects = this.jsDrivenSelectUnitRelationshipTargets.filter(r => r.dataset.jsontype == collection);

      let items = "";
      for (let d of collectionData) {
        items += this.createOption(d, collection);
      }
      this.data.set('optionsUnits', items);

      if(formName == "tech_resource") { 
        if (collection == "units") 
        {
          collectionSelects.forEach(select => {
            let currentValue = select.dataset.currentvalue;
            const placeholder =  `<option></option>` ;
            const itemsWithPlaceholder = placeholder + items
            select.insertAdjacentHTML("afterbegin", itemsWithPlaceholder);
            let selections = Array.from(select.querySelectorAll('option')).filter(o => o.text == String(currentValue));
            selections.forEach(s => {
                s.selected = true;

            });
          });

          continue;
        }
      }
     
      }
    }

    this.initializeDatalists(this.datalistInputTargets);

  }

updateTechResourceUnitRelationship(e) {

  const select = document.querySelector("#tech_resource_unit_ids");
  let array1 = [];
  select.querySelectorAll('option:checked').forEach(so => {
    if (!array1.includes(so.value)) {
      array1.push(so.value); 
    }
  });

  const array2 = [];

  let collectionSelects = this.jsDrivenSelectUnitRelationshipTargets

  collectionSelects.forEach(select => {
    let currentvalue = select.value;
    array2.push(currentvalue); 
  });
  
  const dl = e.target;
  const list = document.querySelector("#unit_idslist");
  const selectedOption = list.querySelector("option[data-value='"+dl.value+"']");
  
  if (selectedOption) {
    select.querySelector("option[value='"+selectedOption.dataset.value+"']").selected = true;
  }

  if(array1.length == array2.length) {
    const differingElement = this.findDifference(array1, array2);

    if (differingElement) {
      select.querySelector("option[value='"+differingElement+"']").selected = false;
    }
  }
}

removeFormFieldsTechResourceUnitRelationship(e) {
  let removeId = e.target.id;
  const selectToRemove = document.querySelector("#tech_resource_unit_rel" + removeId);
  let valueToRemove = selectToRemove.value

  const select = document.querySelector("#tech_resource_unit_ids");

  if (valueToRemove) {
    select.querySelector("option[value='"+valueToRemove+"']").selected = false;
  }

  let ancestor = e.target.closest('[data-target="form.fieldsRow"]');
  ancestor.parentNode.removeChild(ancestor);
}

addFormFieldsTechResUnits() {
  
  let ul = document.getElementById("ulUnitRelationship");
  let i = 0;
  let lastLi = ul.lastElementChild;
  let lastOptions = "";
  if (lastLi){
    let lastSelect = lastLi.getElementsByTagName("select");
    let lastId = lastSelect[0].id.replace('tech_resource_unit_rel', '');
    i = parseInt(lastId, 10) + 1;
  }

  let items = this.data.get('optionsUnits');
  const placeholder =  `<option></option>` ;
  lastOptions = placeholder + items

  let li = document.createElement('li');
  li.className = "exisitngunit_relationship row";
  li.style = "margin-bottom: 0px;";
  li.dataset.target = "form.fieldsRow";

  let mySelect = document.createElement('select');
  mySelect.className = "select";
  mySelect.style = "max-width: 250px;";

  mySelect.id = "tech_resource_unit_rel" + i + "";
  mySelect.name = "tech_resource_unit_rel" + i + "";
  mySelect.dataset.target = "form.jsDrivenSelectUnitRelationship";
  mySelect.dataset.currentvalue = "";
  mySelect.dataset.jsonvalue = "id";
  mySelect.dataset.action = "change->form#updateTechResourceUnitRelationship";
  mySelect.dataset.jsontype = "units";
  mySelect.innerHTML = lastOptions;

  let myDivSelect = document.createElement('div')
  myDivSelect.style = "margin-bottom: 0px; min-width: 250px";
  myDivSelect.appendChild(mySelect);

  let myDivFeature = document.createElement('div')
  myDivFeature.style = "margin-left: 20px;";
  myDivFeature.innerHTML = "<div style='display: flex; flex-direction: row; padding-top: 5px;'><div style='padding-top: 3px;'><input  type='checkbox' style='margin-bottom: 0px;' ></div><div style='padding-bottom: 4px;'><label style='font-weight: bold'>Featured</label></div><div class='tooltip'><span style='margin-left: 10px'><svg class='info-mark icon-circle-info-solid'><use xlink:href='#circle-info-solid'></use></svg></span><span class='tooltip-text' style='top: -20px; margin-left: 10px;'>This field is ONLY for use by Marketing. Turning on 'Featured' status will cause an enabled product to be displayed as 'Featured' on the associated unit page.</span></div><div style='padding-left: 20px; '></div></div> ";
  
  let myLinkRemove = document.createElement('a')
  myLinkRemove.className  = "remove_fields";
  myLinkRemove.id = i ;
  myLinkRemove.href = "javascript:;";
  myLinkRemove.style = "margin-top: 2px; margin-left: 15px;";
  myLinkRemove.dataset.action = "form#removeFormFieldsTechResourceUnitRelationship";
  myLinkRemove.innerHTML= "x";

  let myDivRemove = document.createElement('div')
  myDivRemove.style = "margin-bottom: 0px; margin-left: 0px";
  myDivRemove.appendChild(myLinkRemove);

  li.appendChild(myDivSelect);
  //li.appendChild(myDivFeature);
  li.appendChild(myDivRemove);
  ul.appendChild(li);

  $('#' + "tech_resource_unit_rel" + i).select2({
    placeholder: "Select option"
  });
  $('#' + "tech_resource_unit_rel" + i).on('select2:select', function() {
    let event = new Event('change', {bubbles: true})
    this.dispatchEvent(event);
  })

}

findDifference(array1, array2) {
  // Combine both arrays into a single array
  const combinedArray = array1.concat(array2);

  // Use a Set to filter out duplicate elements
  const uniqueElements = new Set(combinedArray);

  // Create an array from the unique elements Set
  const uniqueArray = Array.from(uniqueElements);

  // Filter the unique array to get the differing element
  const difference = uniqueArray.filter(element => 
      array1.includes(element) !== array2.includes(element)
  );

  return difference.length > 0 ? difference[0] : null;
}



}

